import Button from '@mui/material/Button';
import * as React from 'react';
import { useState} from 'react'
import Typography from '@mui/material/Typography';
import { Grid, Card, CardContent, CardActions, Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Confetti from 'react-confetti'
import useDisplaySize from './hooks/useDisplaySize';
import useBingoRules from './hooks/useBingoRules';
import SubmitModal from 'components/SubmitModal';
import useDiscordChannel from 'hooks/useDiscordChannel';
import { fetchUser, getGlobalLists, updateUser } from 'util/firebase';
import useDiscordLogin from 'hooks/useDiscordLogin';


// Defines the breakpoints for dynamically displaying on sizes
// xs, sm, md, lg, xl
var breakpoints = [1, 2, 3, 5, 5]
var firstIndexLessThanFive = 2

const App = () => {
  const [goals, setGoals] = useState(["-"])
  const [recs, setRecs] = useState(["-"])
  const [userObjectives, setUserObjectives] = useState(Array(25).fill(1))
  const [completed, setCompleted] = useState(Array(25).fill(""))
  const [announced, setAnnounced] = useState(0)
  const [openModal, setOpenModal] = useState(false);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const { getScreenSize } = useDisplaySize()
  const { checkBingo } = useBingoRules()
  const { sendSquareMessage, sendBingoMessage, sendBlackoutMessage, sendIntroMessage } = useDiscordChannel();

  // Auth
  const { loggedIn, user, startOAuthGrant } = useDiscordLogin();

  // modal state
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');


  const handleIDSubmit = async () => {

    const lists = await getGlobalLists()

    const data = await fetchUser(user, lists.recs.length)

    setRecs(lists.recs)
    setGoals(lists.goals)
    setUserObjectives(data.userObjectives)
    setCompleted(data.completed)
    setAnnounced(data.announced)
  };

  React.useEffect(() => {
    if (loggedIn) handleIDSubmit();
  }, [loggedIn]);

  const handleCloseModal = () => {
    setTitle("")
    setAuthor("")
    setOpenModal(false)
    setSelectedCardIndex(null);
  }

  const handleSubmit = async (title, author) => { 
    if ((title.trim() === "" || author.trim() === "") && selectedCardIndex !== 0) return -1

    var idx = selectedCardIndex
    var square = goals[idx]
    var squareTitle = title
    var squareAuthor = author

    let newCompleted = completed
    newCompleted[idx] = `${title} by ${author}`;
    setCompleted(newCompleted)
    handleCloseModal()

    if (idx === 0) {
      sendIntroMessage(user)
    } else {
      sendSquareMessage(user, square, squareTitle, squareAuthor)
    }

    let newAnnounced = announced
    if (checkBingo(userObjectives, newCompleted) && announced === 0) {
      sendBingoMessage(user)
      setAnnounced(1)
      newAnnounced = 1
    }

    if (userObjectives.every(item => newCompleted[item]) && announced === 1) {
      sendBlackoutMessage(user)
      setAnnounced(2)
      newAnnounced = 2
    }

    await updateUser(user, newCompleted, newAnnounced)
  };
  return ( 
    <Box> 
      <Box justifyContent="center" width="100%" alignItems="center" display="flex" textAlign="center"
        paddingY={2} marginBottom={2} style={{backgroundColor: "#c47339"}} borderRadius={2}>

        {(announced === 2) ? 
          <Confetti numberOfPieces={400}/> : ((announced === 1) ? <Confetti numberOfPieces={100} recycle={false}/> : null)}


        <Box marginLeft={3} marginRight={1}>
          {(!!user && !(user === "undefined")) ? 
            <Typography variant="subtitle1" fontWeight="bold" >Your ID is: {user}</Typography>   
          :
          <>
            {((goals[0] === "-") || user === "undefined") && (
              <Typography variant="subtitle1" fontWeight="bold">Welcome to the Boiler Book Club Bingo! Log In Here:</Typography>
            )}
            <Button variant="contained" onClick={startOAuthGrant}>
              Log In
            </Button>
            {(user === "undefined") && (<Typography variant="subtitle1" fontWeight="bold">Example Card:</Typography>)}
          </>
          }
        </Box>
      </Box>

      <Box marginX={2}>
        <Grid container columnSpacing={2} rowSpacing={2}>
          {userObjectives.map((index, i) => (
            <React.Fragment key={i}>
              <Grid item xs={12 / breakpoints[0]} sm={12 / breakpoints[1]} 
                md={12 /breakpoints[2]} lg={12 / breakpoints[3]} xl={12 / breakpoints[4]}>
                <Card style={{backgroundColor: (!completed[index] ? '#fff1e6' : '#5ad17a'), height: '100%', 
                  display: "flex", flexDirection: "column"}}>
                  <CardContent>
                    <Typography variant="h6" component="h2">
                      {goals[index % goals.length]}
                    </Typography>
                    {(index !== 0 && (recs[index % recs.length] !== "–" || completed[index])) && <Typography color="textSecondary" variant="body2">
                      {!completed[index] ? ("We Recommend: " +recs[index % recs.length]) : ("You Read: " + completed[index])}
                    </Typography>
                    }
                  </CardContent>
                  <CardActions disableSpacing sx={{ mt: "auto"}}>
                    <Box justifyContent="center" alignItems="bottom" display="flex" width="100%" height="100%">
                      <Button size="small" color="primary" disabled={user === "" || user === "undefined" || completed[index] !== ""} 
                        onClick={() => {setSelectedCardIndex(index); setOpenModal(true)}}>Submit</Button>
                    </Box>
                  </CardActions>
                </Card>
              </Grid>
              {(((i+1) % 5 === 0 && i !== 0) && getScreenSize() <= firstIndexLessThanFive) ?
                <Grid item xs={12} marginY={1}>
                  <Divider variant="middle" flexItem sx={{ 
                    borderBottomWidth: 4, backgroundColor: "primary.main" }}/>
                </Grid> : <></> 
              }
            </React.Fragment>
          ))}
        </Grid>
      </Box>

      <SubmitModal open={openModal} handleClose={handleCloseModal} title={title} 
        setTitle={setTitle} author={author} setAuthor={setAuthor}
        handleSubmit={handleSubmit} goals={goals} selectedCardIndex={selectedCardIndex}/>
      
      <Box width="100%" display="flex" justifyContent="center" alignItems="center" paddingTop={3}>
        <Box
          component="img"
          alt="Club logo"
          src="logo.ico"
        /> 
        <Typography variant="caption">Boiler Book Club 2024</Typography>
      </Box>
    </Box>
  );
};

export default App;
