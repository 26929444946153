import axios from "axios";

// The webhook url will have to be moved to a .env

const useDiscordChannel = () => {

  // there should really just be a sendMessage() method lol

  const sendIntroMessage = async (user) => {
    try {
      const webhookUrl = 'https://discord.com/api/webhooks/1239625160458371144/k6E9JhSIN7torlrHl__mif3BhTkAobt_uP1m9fazBrNfxlalSwIGVhaqNa4UZfKq7ck4'
      await axios.post(webhookUrl, {
        content: `<@${user}> has introduced themselves! Go say hi in <#971186275719721021>.`,
      });
    } catch (error) {
      console.error('Error sending message to Discord:', error);
      alert('Error confirming bingo: ' + error.message);
    }
  }

  const sendSquareMessage = async (user, square, title, author) => {
    try {
      const webhookUrl = 'https://discord.com/api/webhooks/1239625160458371144/k6E9JhSIN7torlrHl__mif3BhTkAobt_uP1m9fazBrNfxlalSwIGVhaqNa4UZfKq7ck4'
      await axios.post(webhookUrl, {
        content: `<@${user}> has completed the square **${square}** with the book **${title}** written by **${author}**!`,
      });
    } catch (error) {
      console.error('Error sending message to Discord:', error);
      alert('Error submitting book: ' + error.message);
    }
  }

  const sendBingoMessage = async (user) => {
    try {
      const webhookUrl = 'https://discord.com/api/webhooks/1239625160458371144/k6E9JhSIN7torlrHl__mif3BhTkAobt_uP1m9fazBrNfxlalSwIGVhaqNa4UZfKq7ck4'
      await axios.post(webhookUrl, {
        content: `<@${user}> has completed a **BINGO!**`,
      });
    } catch (error) {
      console.error('Error sending message to Discord:', error);
      alert('Error confirming bingo: ' + error.message);
    }
  } 

  const sendBlackoutMessage = async (user) => {
    try {
      const webhookUrl = 'https://discord.com/api/webhooks/1239625160458371144/k6E9JhSIN7torlrHl__mif3BhTkAobt_uP1m9fazBrNfxlalSwIGVhaqNa4UZfKq7ck4'
      await axios.post(webhookUrl, {
        content: `<@${user}> has completed a **BLACKOUT!** Congratulate them[!](<https://www.youtube.com/watch?v=S7JqVjyZXpc>)`,
      });
    } catch (error) {
      console.error('Error sending message to Discord:', error);
      alert('Error confirming blackout: ' + error.message);
    }
  }

  return { sendSquareMessage, sendBingoMessage, sendBlackoutMessage, sendIntroMessage }
}

export default useDiscordChannel