import { getFirestore, doc, setDoc, getDoc, updateDoc } from "firebase/firestore"; 
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDgLtUeTB-pPNzRX3vfpyIlY6hlX2ovpOA",
  authDomain: "book-bingo-2023.firebaseapp.com",
  databaseURL: "https://book-bingo-2023-default-rtdb.firebaseio.com",
  projectId: "book-bingo-2023",
  storageBucket: "book-bingo-2023.appspot.com",
  messagingSenderId: "241923179402",
  appId: "1:241923179402:web:54986a8197cf097c68fe73"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Fetches the full list of recs and goals
// Note this is a really bad way of doing it if we had a lot of them lol
export const getGlobalLists = async () => {
  let path = doc(db, "globals", "lists")
  const snapshot = await getDoc(path)
  let recs = snapshot.data()['recs']
  let goals = snapshot.data()['goals']

  return { recs, goals }
}

// Given some id, either fetches the user if it exists or creates it.
// Will return { userObjectives, completed, announced }
// There should be some error checking the code is valid somewhere too
export const fetchUser = async (user, goalsBound) => {
  let path = doc(db, "users", '' + user)
  const snapshot = await getDoc(path)
  let doesExist = snapshot.exists()

  if (doesExist) {
    return snapshot.data()
  }

  var arr = [];
  while(arr.length < 25){
      var r = Math.floor(Math.random() * (goalsBound  - 1)) + 1;
      if(arr.indexOf(r) === -1) arr.push(r);
  }

  let data = {
    userObjectives: arr,
    completed: Array(goalsBound).fill(""),
    announced: 0
  }

  data.userObjectives[12] = 0
  await setDoc(path, data)

  return data
}

// Updates completed and announced to the params
export const updateUser = async (user, completed, announced) => {
  await updateDoc(doc(db, "users", '' + user), {
    completed: completed,
    announced: announced
  })

}