const useBingoRules = () => {

  const checkBingo = (squares, completed) => {

    const e = (i) => {
      return (completed[i] !== "")
    }

    for (let i = 0; i < 25; i += 5) {
      if (e(squares[i]) && e(squares[i + 1]) && e(squares[i + 2]) && e(squares[i + 3]) && e(squares[i + 4])) {
        return true;
      }
    }
    for (let i = 0; i < 5; i++) {
      if (e(squares[i]) && e(squares[i + 5]) && e(squares[i + 10]) && e(squares[i + 15]) && e(squares[i + 20])) {
        return true;
      }
    }
    if (e(squares[0]) && e(squares[6]) && e(squares[12]) && e(squares[18]) && e(squares[24])) {
      return true;
    }
    if (e(squares[4]) && e(squares[8]) && e(squares[12]) && e(squares[16]) && e(squares[20])) {
      return true;
    }

    return false;
  }

  return { checkBingo } 

}

export default useBingoRules