import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const useDisplaySize = () => {
    // Programmaticaly access screen size
    const theme = useTheme()
    const isXs = useMediaQuery(theme.breakpoints.up('xs'))
    const isSm = useMediaQuery(theme.breakpoints.up('sm'))
    const isMd = useMediaQuery(theme.breakpoints.up('md'))
    const isLg = useMediaQuery(theme.breakpoints.up('lg'))
    const isXl = useMediaQuery(theme.breakpoints.up('xl'))
  
    const getScreenSize = () => {
      if (isXl) return 4
      if (isLg) return 3
      if (isMd) return 2
      if (isSm) return 1
      if (isXs) return 0
      return -1
    }

    return { getScreenSize } 
}

export default useDisplaySize