import { useEffect, useState } from "react";

const DISCORD_ID = "1105382108354056202";

export default function useDiscordLogin() {
    const [user, setUser] = useState("");

    useEffect(() => {
        const storedUser = localStorage.getItem("discordUser");
        if (storedUser) {
            setUser(storedUser);
        }

        const params = new URLSearchParams(window.location.hash.slice(1));
        const accessToken = params.get("access_token");
        if (accessToken) {
            fetch("https://discord.com/api/users/@me", {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            })
                .then((res) => res.json())
                .then((user) => {
                    setUser(user.id);
                    localStorage.setItem("discordUser", user.id);
                }
            );
        }
    
    }, []);

    const startOAuthGrant = () => {
        const redirect = encodeURIComponent(`${window.location.origin}/`);
        window.open(
            `https://discord.com/api/oauth2/authorize?client_id=${DISCORD_ID}&redirect_uri=${redirect}&response_type=token&scope=identify`,
            "_parent"
          );
    }

    return { loggedIn: user !== "", user, startOAuthGrant }
}