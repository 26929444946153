import { Box, Button, Chip, Modal, Stack, TextField, Typography } from "@mui/material"
import React from "react";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #bb4d00',
  boxShadow: 24,
  p: 4,
};

const useSubmitModal = ({
  open, handleClose, handleSubmit, goals, selectedCardIndex, 
  title, setTitle, author, setAuthor
}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open} onClose={handleClose}
    >
      <Box sx={modalStyle} textAlign="center">
        <Typography id="transition-modal-title" variant="h6">
          {`${goals[selectedCardIndex]}`}
        </Typography>
        {selectedCardIndex !== 0 && 
        <>
          <Stack justifyContent="start" alignItems="left" display="flex" width="100%" marginTop={2} spacing={1}>
            <TextField
              required
              id="title-field"
              label="Book Title"
              size="small"
              value = {title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              required
              id="author-field"
              label="Book Author"
              size="small"
              value = {author}
              onChange={(e) => setAuthor(e.target.value)}
            />
          </Stack>
          <br />
          <Chip icon={<InfoOutlinedIcon />}  style={{ fontSize: 10 }}
          label="Each book you read can be used to fill up to two squares" variant="outlined" />
        </>
        }
        <Box marginTop={2}>
          <Button onClick={() => handleSubmit(title, author)}>
            {selectedCardIndex ? "Submit" : "Mark as Completed"}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default useSubmitModal